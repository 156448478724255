import { OrderProvider } from "@Contexts/OrderContext";
import { PublicCatalogProvider } from "@Contexts/PublicCatalogContext";
import { QuotationProvider } from "@Contexts/QuotationContext";
import { useUser } from "@Hooks/firebase";
import { Auth } from "@Pages/Auth";
import { Home } from "@Pages/Home";
import { Catalog } from "@Pages/Home/Catalog";
import { MyCart } from "@Pages/Home/MyCart";
import { MyOrders } from "@Pages/Home/MyOrders";
import { MyQuotations } from "@Pages/Home/MyQuotations";
import { OrderDetails } from "@Pages/Home/OrderDetails";
import { OrderValidate } from "@Pages/Home/OrderValidate";
import { ProductDetails } from "@Pages/Home/ProductDetails";
import { Profile } from "@Pages/Home/Profile";
import { PublicCatalog } from "@Pages/Home/PublicCatalog";
import { Quotation } from "@Pages/Home/Quotation";
import { QuotationReview } from "@Pages/Home/QuotationReview";
import { QuotationValidate } from "@Pages/Home/QuotationValidate";
import { mergeContexts } from "@Utils/context.utils";
import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { CartProvider } from "./Contexts/CartProvider";
import { CatalogProvider } from "./Contexts/CatalogContext";
import { PageHeaderProvider } from "./Contexts/PageHeaderProvider";

const MobileApp = styled.div`
  min-width: 390px;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const AppContextProvider = mergeContexts([
    CatalogProvider,
    CartProvider,
    QuotationProvider,
    OrderProvider
]);
export const App = () => {

    const user = useUser();

    return (
        <MobileApp>
            <PageHeaderProvider>
                {
                    !user?.isConnected ?
	                    <PublicCatalogProvider>
		                    <Routes>
			                    <Route path="login" element={<Auth isLogin/>}/>
			                    <Route path="public" element={<PublicCatalog />} />
			                    <Route path="forgotten-password" element={<Auth isForgottenPassword/>}/>
			                    <Route path="register/:code" element={<Auth isSignup/>}/>
			                    <Route path="*" element={<Navigate to={"/login"}/>}/>
		                    </Routes>
	                    </PublicCatalogProvider>
                        :
                        <AppContextProvider>
                            <Routes>
                                <Route path="home" element={<Home/>}>
                                    <Route index element={<Navigate to="catalog"/>}/>
                                    <Route path="catalog" element={<Catalog/>}/>
                                    <Route path="cart" element={<MyCart/>}/>
                                    <Route path="order" element={<OrderValidate/>}/>
                                    <Route path="quotation" element={<Quotation />} />
                                    <Route path="my-orders" element={<MyOrders/>}/>
                                    <Route path="my-orders/:orderUid" element={<OrderDetails/>}/>
                                    <Route path="my-quotations" element={<MyQuotations />}/>
                                    <Route path="my-quotations/:quotationUid" element={<QuotationReview />}/>
                                    <Route path="my-quotations/:quotationUid/edit" element={<QuotationValidate />}/>
                                    <Route path=":reference" element={<ProductDetails/>}/>
                                    <Route path=":reference/edit" element={<ProductDetails edit/>}/>
                                    <Route path="profile/*" element={<Profile/>}/>
                                </Route>
                                <Route path="*" element={<Navigate to={"/home"}/>}/>
                            </Routes>
                        </AppContextProvider>
                }
            </PageHeaderProvider>
        </MobileApp>
    )
}
