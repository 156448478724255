import React, {useCallback, useContext, useEffect, useState} from "react";
import "./Search.scss";
import {SwippableModal} from "@Components/SwippableModal";
import {Product} from "@Types/Product";
import searchImage from "@Assets/images/search-wheels-image.png";
import pneuImage from "@Assets/images/pneu-search-image.png";
import rnImage from "@Assets/images/rouenue-search-image.png";
import chambreImage from "@Assets/images/chambre-search-image.png";
import completeImage from "@Assets/images/rouecomp-search-image.png";
import micIcon from "@Assets/images/mic-icon.svg";

import {AutoComplete, AutoCompleteCompleteMethodParams} from "primereact/autocomplete";
import {Button} from "primereact/button";
import {Tag} from "primereact/tag";
import SpeechRecognition, {useSpeechRecognition} from "react-speech-recognition";

export type SearchProps = {
    visible: boolean;
    setVisible: (value: boolean) => void;
    products: Product[];
    onFilter: (reference: string, family: "CH" | "EN" | "RN" | "RC") => void;
}
export const Search: React.FC<SearchProps> = props => {

    const [selectedProduct, setSelectedProduct] = useState<Product | string | undefined>(undefined);
    const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
    const [step, setStep] = useState<number>(0);
    const [selectedFamily, setSelectedFamily] = useState<"CH" | "EN" | "RN" | "RC">();

    const {
        transcript,
        listening,
        resetTranscript,
        isMicrophoneAvailable,
        finalTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    const onStartRec = async () => {
        resetTranscript();
        setSelectedProduct(undefined);
        if (browserSupportsSpeechRecognition) {
            await SpeechRecognition.startListening({
                language: 'fr-Fr',
            });
        }
    };

    const onStopRec = () => {
        console.log('stop')
        SpeechRecognition.stopListening();
    };

    useEffect(() => {
        if (listening) {
            setSelectedProduct(transcript.replaceAll(/\D/ig, ''));
        } else if (!listening && finalTranscript) {
            setSelectedProduct(finalTranscript.replaceAll(/\D/ig, ''));
        }
    }, [transcript, listening, finalTranscript]);


    const searchCompleteMethod = (param: AutoCompleteCompleteMethodParams) => {
        const products = props.products.filter(p => p.meta['Famille Divalto'].trim() === selectedFamily).filter(product => {
            const regexDes = new RegExp("^"+param.query, "ig");
            const transformedDes = product.des.replaceAll(/\D/ig, '');
            return regexDes.test(transformedDes) || regexDes.test(product.reference);
        }).map(e => ({
            ...e,
            des: e.des.trim(),
        }))

        setFilteredProducts(products);
    }


    const onImageClick = (family: "CH" | "RN" | "RC" | "EN") => {
        setSelectedFamily(family);
        setStep(1);
    }

    const productTemplate = (product: Product) => {
        return <div className="p-autocomplete-panel-custom flex flex-column my-2">
            <div className="flex">
                <Tag value={product.brand} severity="info" className="p-autocomplete-panel-custom-tag mb-2 mr-2 w-min"/>
                <Tag value={product.reference} className="p-autocomplete-panel-custom-tag mb-2 mr-2 w-min"/>
            </div>
            <span className="p-autocomplete-panel-custom-label max-w-full" >
                {product.des}
            </span>
        </div>
    }

    const onSearch = useCallback((ref?: string) => {
        if (selectedProduct && selectedFamily) {
            props.onFilter(ref ?? (typeof selectedProduct === "string" ? selectedProduct : selectedProduct.reference), selectedFamily);
            props.setVisible(false);
            setSelectedProduct(undefined);
            setStep(0);
        }
    }, [selectedProduct, selectedFamily]);

    return (
        <SwippableModal visible={props.visible} onHide={() => {
            setSelectedProduct(undefined);
            setStep(0);
            props.setVisible(false)
        }}>
            <div className="pt-4 flex flex-column">
                {
                    step === 0 ? <>
                            <div>
                                <div className="Search-filter-text mb-3">Sélectionner une catégorie :</div>
                                <div className="Search-image-container">
                                    <div className="Search-image-container-item" onClick={() => onImageClick('EN')}>
                                        <img src={pneuImage} alt="Pneu"/>
                                        <span>
                                        PNEU
                                    </span>
                                    </div>
                                    <div className="Search-image-container-item" onClick={() => onImageClick('RN')}>
                                        <img src={rnImage} alt="Roues nues"/>
                                        <span>
                                        ROUES NUES
                                    </span>
                                    </div>
                                    <div className="Search-image-container-item" onClick={() => onImageClick('CH')}>
                                        <img src={chambreImage} alt="Chambre à air"/>
                                        <span>
                                        CHAMBRES À AIR
                                    </span>
                                    </div>
                                    <div className="Search-image-container-item" onClick={() => onImageClick('RC')}>
                                        <img src={completeImage} alt="Roue complète"/>
                                        <span>
                                        ROUES COMPLÈTES
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </>
                        : <>
                            <div className="flex justify-content-center align-items-center">
                                <img src={searchImage} alt="search" style={{width: "276px", height: "auto"}}/>
                            </div>
                            <div className="Search-input-wrapper flex flex-column justify-content-center w-full mb-3">
                                <div className="Search-input-autocomplete">
                                    <div className="Search-input-autocomplete-icon">
                                        <i className="pi pi-search" style={{color: "#98A2B3"}}/>
                                    </div>
                                    <AutoComplete
                                        appendTo="self"
                                        type="number"
                                        inputMode={"decimal"}
                                        field="reference"
                                        suggestions={filteredProducts}
                                        pattern="\d*"
                                        completeMethod={searchCompleteMethod}
                                        value={selectedProduct}
                                        itemTemplate={productTemplate}
                                        onChange={(e) => {
                                            setSelectedProduct(e.value);
                                        }}
                                        onSelect={e => {
                                            onSearch(e.value.reference);
                                        }}
                                        onKeyDown={e => {
                                            if (['NumpadEnter', 'Enter'].includes(e.code)) {
                                                onSearch();
                                            }
                                        }}
                                        placeholder="Référence ou désignation"/>
                                    <div className="Search-input-autocomplete-icon right-0 top-0 mr-1" onClick={listening ? onStopRec : onStartRec}>
                                        {listening ?
                                            <i className="pi pi-stop"/>
                                            :
                                            <img src={micIcon} alt="Saisie vocale"/>
                                        }
                                    </div>
                                </div>
                                <div className="Search-input-example-text mt-2">
                                    ex: 105001, 56060225
                                </div>
                            </div>
                            <div className="Search-input-infotext flex justify-content-start align-items-center mb-3">
                                <i className="flex-shrink-1 pi pi-info-circle mr-2 ml-1"/>
                                <span className="flex-1">
                                    Saisir seulement les chiffres de la désignation à la suite, aucun caractère spécial ou lettre.
                                </span>
                            </div>
                            <div className="flex flex-column px-3">
                                <Button label="Afficher le résultat" onClick={() => onSearch()}/>
                                <Button label="Annuler" className="fps__button--secondary-no-border" onClick={() => {
                                    setSelectedProduct(undefined);
                                    setStep(0);
                                }}/>
                            </div>
                        </>
                }
            </div>
        </SwippableModal>
    )
}
